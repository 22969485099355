import CircularProgress from "@mui/material/CircularProgress";

interface BasicLoaderProps {
  tailwindClassName?: string;
}

const BasicLoader = ({ tailwindClassName }: BasicLoaderProps) => {
  return (
    <div
      className={`grid place-items-center w-full ${
        tailwindClassName ? tailwindClassName : "min-h-[100vh]"
      }`}
    >
      <CircularProgress />
    </div>
  );
};

export default BasicLoader;
