import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Emoji404 from "../../icons/Emoji404";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box className="flex flex-col justify-center items-center min-h-[100vh] pb-24">
      <Emoji404 />
      <div className="tracking-widest mt-4 text-center">
        <span className="text-gray-500 text-6xl block">
          <span>4 0 4</span>
        </span>
        <span className="text-gray-500 text-xl">
          Sorry, We couldn't find what you are looking for!
        </span>
      </div>
      <div
        className="text-gray-500 font-mono text-xl bg-gray-200 p-3 rounded-md hover:shadow-md mt-1 cursor-pointer"
        onClick={() => navigate("/")}
      >
        Back Home
      </div>
    </Box>
  );
};

export default NotFound;
