import { Routes, Route } from "react-router-dom";
import SetLanguage from "../modules/auth/pages/SetLanguage";
import NotFound from "../modules/common/pages/error/NotFound";

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<SetLanguage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MainRoute;
