import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface BasicDialogProps {
  open: boolean;
  onClose: any;
  title: string;
  content: any;
  actions: any;
}

const BasicDialog = ({
  open,
  onClose,
  title,
  content,
  actions,
}: BasicDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} className="w-[100vw]" maxWidth={"xl"}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BasicDialog;
