import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../common/components/toast";
import { callAxios } from "../../../plugins/axios";
import BasicLoader from "../../common/components/BasicLoader";
import BasicDialog from "../../common/components/BasicDialog";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BasicCopyright from "../../common/components/BasicCopyright";
import { validationError } from "../../../utils/ValidationError";

//interfaces
interface IRows {
  id: number;
  name: string;
}

interface IFormData {
  serial_number: string;
  language_id: string;
}

const SetLanguage = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [rows, setRows] = React.useState<IRows[]>([]);
  const [formData, setFormData] = React.useState<IFormData>({
    serial_number: "",
    language_id: "",
  });
  const [loader, setLoader] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<any>({});
  const handleCloseDialog = () => {
    setOpenModal(false);
    setErrors({});
  };

  //onchange input handler
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    console.log(`name: ${name}, value: ${value}`);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //final dialog action handler for modal
  const onClickDialogActionHandler = async () => {
    setLoader(true);
    try {
      const res = await callAxios({
        method: "PUT",
        url: "device/language",
        data: formData,
      });

      //response handling
      if (res?.status === 200) {
        toast("Device Language Updated", successToast);
        setFormData({
          serial_number: "",
          language_id: "",
        });
        handleCloseDialog();
        navigate("/");
      } else if (res?.response?.status === 400) {
        validationError(res);
      } else {
        toast(res?.response?.statusText, errorToast);
      }
    } catch (error) {
      toast("Something went wrong", errorToast);
    }
    setLoader(false);
  };

  //modal
  const confirmDialog = (
    <>
      <div className="p-2 grid grid-col-1 gap-4">
        Are you sure you want to continue this action?
      </div>
    </>
  );

  const dialogAction = (
    <>
      <Button variant="outlined" color="error" onClick={handleCloseDialog}>
        No
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onClickDialogActionHandler()}
      >
        Yes
      </Button>
    </>
  );

  //form validation with proper error messages
  const validateForm = () => {
    const regexPattern = /^\d{14,20}$/;
    const newErrors: any = {};

    if (!formData?.serial_number?.trim()) {
      newErrors.serial_number = "Serial Number is required";
    }

    if (
      formData?.serial_number?.trim() &&
      !regexPattern.test(formData?.serial_number)
    ) {
      newErrors.serial_number = "Serial Number is invalid";
    }

    if (formData?.language_id === "") {
      newErrors.language_id = "Language is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //onsubmit form handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setOpenModal(true);
    }
  };

  //async functions for useEffect
  const loadAvailableLanguageData = async () => {
    setLoader(true);
    try {
      let res = await callAxios({
        method: "GET",
        url: "device/language/list",
      });

      //response handling
      if (res?.status === 200) {
        setRows(res?.data?.languages);
      } else {
        toast(res?.response?.statusText, errorToast);
      }
    } catch (error: any) {
      toast("Something went wrong", errorToast);
    }
    setLoader(false);
  };

  //useEffect
  React.useEffect(() => {
    loadAvailableLanguageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      {loader ? (
        <BasicLoader />
      ) : (
        <Container component="main" maxWidth="sm">
          <BasicDialog
            open={openModal}
            onClose={handleCloseDialog}
            title={"Set Language"}
            content={confirmDialog}
            actions={dialogAction}
          />
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="flex flex-col justify-center items-center w-full gap-y-8">
              <div className="w-2/3 mr-10">
                <img src="/logo.svg" alt="koili_logo" />
              </div>
              <div className="flex flex-col w-full items-center justify-center">
                <Box
                  component="form"
                  className="w-full"
                  onSubmit={handleSubmit}
                  sx={{ mb: 1 }}
                >
                  <TextField
                    margin="normal"
                    fullWidth
                    placeholder="XXXXXXXXXXXXXXX"
                    id="serial_number"
                    label="Serial Number"
                    type="number"
                    name="serial_number"
                    autoComplete="serial_number"
                    autoFocus
                    value={formData?.serial_number}
                    onChange={handleInputChange}
                    error={!!errors.serial_number}
                    helperText={errors.serial_number}
                  />
                  <div className="mt-2">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        <span
                          className={`${errors.language ? "text-red-500" : ""}`}
                        >
                          Language
                        </span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Language"
                        name="language_id"
                        onChange={handleInputChange}
                        value={formData?.language_id}
                        error={!!errors.language_id}
                      >
                        {rows?.map((data: IRows, key: number) => (
                          <MenuItem value={data?.id} key={key}>
                            {data?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.language_id && (
                        <Typography
                          variant="caption"
                          color="error"
                          className="pl-4"
                        >
                          {errors.language_id}
                        </Typography>
                      )}
                    </FormControl>
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Confirm IPN Language
                  </Button>
                </Box>
                <BasicCopyright sx={{ mt: 8, mb: 4 }} />
              </div>
            </div>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default SetLanguage;
